import { GameOutput } from "../types/gameOutput.ts";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  chapterPromptsElementsAtom,
  currentCharacterEmotionState,
  isChapterCompleteState,
  isGameLostState,
  objectiveHasJustBeenCompletedState,
  useCreatePlayerChapters,
  useUpdatePlayerChapter,
} from "../states/chapterState.tsx";
import { Tables } from "../types/database.ts";
import {
  audioDataQueueState,
  isThinkingState,
  playedChaptersState,
} from "../states/audioDataState.ts";

interface ChapterManagerHookProps {
  gameOutput: GameOutput | null;
}

const useChapterManager = ({ gameOutput }: ChapterManagerHookProps) => {
  const [chapterPrompts] = useRecoilState(chapterPromptsElementsAtom);
  const chapterPromptsRef = useRef(chapterPrompts);
  const updatePlayerChapter = useUpdatePlayerChapter();
  const createPlayerChapter = useCreatePlayerChapters();
  const [, setIsChapterComplete] = useRecoilState(isChapterCompleteState);
  const [, setIsGameLost] = useRecoilState(isGameLostState);
  const [, setIsThinking] = useRecoilState(isThinkingState);
  const [, setObjectiveHasJustBeCompleted] = useRecoilState(objectiveHasJustBeenCompletedState);
  const [, setCurrentCharacterEmotion] = useRecoilState(currentCharacterEmotionState);
  const [playedChapters] = useRecoilState(playedChaptersState);
  const [audioQueue] = useRecoilState(audioDataQueueState);

  useEffect(() => {
    chapterPromptsRef.current = chapterPrompts;
  }, [chapterPrompts]);

  useEffect(() => {
    if (!gameOutput) return;
    console.log(gameOutput);
    updatePlayerChapterWithGameOutput(gameOutput);
    setIsThinking(false);
  }, [gameOutput]);

  useEffect(() => {
    if (!gameOutput) return;
    manageChapterState(gameOutput);
  }, [audioQueue, playedChapters, gameOutput]);

  const updatePlayerChapterWithGameOutput = async (gameOutput: GameOutput) => {
    const chapterPrompt = chapterPromptsRef.current[0];
    const updatedDialogueHistory = getUpdatedDialogue(gameOutput, chapterPrompt);
    const updateObject = {
      id: chapterPrompt.player_chapter_id ?? undefined,
      dialogue_history: updatedDialogueHistory,
      player_facts: gameOutput.player_facts,
    };

    await updatePlayerChapter(updateObject);
    console.log("messageHandler updateObject", updateObject);
  };

  const manageChapterState = async (gameOutput: GameOutput) => {
    const chapterPrompt = chapterPromptsRef.current[0];
    if (!chapterPrompt) return;
    if (!chapterPrompt.player_chapter_id) return;
    if (!chapterPrompt.player_story_id || !chapterPrompt.user_id) return;

    const isChapterPlayed = playedChapters.includes(chapterPrompt.player_chapter_id);

    if (audioQueue.length === 0 && isChapterPlayed && gameOutput.complete_the_chapter) {
      const newChapter = await createPlayerChapter({
        player_story_id: chapterPrompt.player_story_id,
        blueprint_chapter_id: gameOutput.next_chapter_id,
        user_id: chapterPrompt.user_id,
      });
      console.log("newChapter created because one ended: ", newChapter);
      setIsChapterComplete(gameOutput.complete_the_chapter);
    } else if (audioQueue.length === 0 && isChapterPlayed && gameOutput.end_of_game) {
      setIsGameLost(true);
    } else {
      console.log("Chapter continue: ", gameOutput);
      setObjectiveHasJustBeCompleted(gameOutput.objective_has_just_been_completed);
      setCurrentCharacterEmotion({
        emotionKey: gameOutput.character_emotion_key,
        emotionStrength: gameOutput.character_emotion_strength,
      });
    }
  };

  const getUpdatedDialogue = (
    gameOutput: GameOutput,
    chapterPrompt: Tables<"view_chapter_prompt">,
  ) => {
    const history = Array.isArray(chapterPrompt.dialogue_history)
      ? [...chapterPrompt.dialogue_history]
      : [];
    if (gameOutput.transcribed_player_answer) {
      history.push({ player: gameOutput.transcribed_player_answer });
    }
    history.push({ llm: gameOutput.dialogue });
    return history;
  };
};

export default useChapterManager;
