// components/imageEffects/LightningArcEffect.tsx
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { getDepthMapUrl } from "../../utils/mediaUtil.ts";
import { useRecoilState } from "recoil";
import { lastImageEffectConfigState } from "../../states/effectState.ts";
import { buildControlsIfAdmin } from "../../utils/imageEffect.ts";

interface LightningArcEffectProps {
  composer: EffectComposer | null;
  texture: THREE.Texture;
  depthMapTextureURL: string;
  params?: any;
}

interface LightningArcEffectParams {
  threshold: number;
  speed: number;
  intensity: number;
}

const defaultLightningArcEffectParams: LightningArcEffectParams = {
  threshold: 0.001,
  speed: 5,
  intensity: 0.5,
};

const LightningArcEffect: React.FC<LightningArcEffectProps> = ({
  composer,
  texture,
  depthMapTextureURL,
  params,
}) => {
  const { size } = useThree();
  const timeRef = useRef(0);
  const depthMapTexture = useLoader(TextureLoader, getDepthMapUrl(depthMapTextureURL));
  const [, setShaderParams] = useRecoilState(lastImageEffectConfigState);

  const shaderParams =
    params ||
    buildControlsIfAdmin({
      threshold: {
        value: defaultLightningArcEffectParams.threshold,
        min: 0,
        max: 1,
        step: 0.001,
      },
      speed: {
        value: defaultLightningArcEffectParams.speed,
        min: 0,
        max: 10,
        step: 0.1,
      },
      intensity: {
        value: defaultLightningArcEffectParams.intensity,
        min: 0,
        max: 1,
        step: 0.01,
      },
    });
  const memoizedSetShaderParams = useCallback(() => {
    setShaderParams(shaderParams);
  }, [JSON.stringify(shaderParams), setShaderParams]);

  useEffect(() => {
    memoizedSetShaderParams();
  }, [memoizedSetShaderParams]);

  const customPass = useMemo(() => {
    const customEffect = {
      uniforms: {
        u_time: { value: 0 },
        u_image: { value: texture },
        u_depthMap: { value: depthMapTexture },
        u_resolution: { value: new THREE.Vector2(size.width, size.height) },
        u_threshold: { value: shaderParams.threshold },
        u_speed: { value: shaderParams.speed },
        u_intensity: { value: shaderParams.intensity },
      },
      vertexShader: `
        varying vec2 v_uv;
        void main() {
          v_uv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float u_time;
        uniform sampler2D u_image;
        uniform sampler2D u_depthMap;
        uniform vec2 u_resolution;
        uniform float u_threshold;
        uniform float u_speed;
        uniform float u_intensity;
        varying vec2 v_uv;

        #define PI 3.14159265359

        float rand(vec2 co) {
          return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
        }

        vec2 randomArc(vec2 uv, float t) {
          float a = rand(vec2(t)) * 2.0 * PI;
          float r = rand(vec2(t + 1.0)) * 0.5 + 0.1;
          return uv + vec2(r * cos(a), r * sin(a));
        }

        void main() {
          vec2 uv = v_uv;
          vec4 depthColor = texture2D(u_depthMap, uv);
          float depth = depthColor.r;

          float depthMask = step(u_threshold, depth);

          vec2 arcUv = randomArc(uv, u_time * 0.8);
          float arcDepth = texture2D(u_depthMap, arcUv).r;
          float arcMask = step(u_threshold, arcDepth);

          vec4 originalColor = texture2D(u_image, uv);
          vec4 arcColor = texture2D(u_image, arcUv);

          float intensity = sin(u_time * u_speed) * 0.5 + u_intensity;
          vec4 lightningColor = vec4(1.0, 1.0, 1.0, intensity);

          vec4 finalColor = mix(originalColor, arcColor, arcMask * lightningColor.a);
          finalColor = mix(finalColor, lightningColor, arcMask * lightningColor.a);
          finalColor = mix(finalColor, originalColor, depthMask);

          gl_FragColor = finalColor;
        }
      `,
    };

    return new ShaderPass(customEffect);
  }, [
    size,
    texture,
    depthMapTexture,
    shaderParams.threshold,
    shaderParams.speed,
    shaderParams.intensity,
  ]);

  useEffect(() => {
    if (composer) {
      composer.addPass(customPass);
    }

    return () => {
      if (composer) {
        composer.removePass(customPass);
      }
    };
  }, [composer, customPass]);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    timeRef.current = elapsedTime;
    customPass.uniforms.u_time.value = timeRef.current;
  });

  return null;
};

export default LightningArcEffect;
