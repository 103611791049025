import React from "react";
import { Input } from "../catalyst/input.tsx";

interface FormFieldProps {
  value: number | null;
  onChange: (step: number) => void;
  label?: string;
}

const NumericStepper: React.FC<FormFieldProps> = ({ value, onChange, label }) => {
  return (
    <div className="flex items-center">
      {label && <label className="font-bold text-sm">{label}</label>}
      <div className="ml-2" style={{ width: "70px" }}>
        <Input
          type="number"
          value={value || 5}
          onChange={(e) => onChange(parseInt(e.target.value))}
        />
      </div>
    </div>
  );
};

export default NumericStepper;
