import React, { ReactNode } from "react";

interface FormFieldProps {
  label?: string;
  children: ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ label, children }) => {
  return (
    <div className="mb-8">
      {label && <label className="block font-bold mb-2 text-sm ">{label}</label>}
      <div className="">{children}</div>
    </div>
  );
};

export default FormField;
