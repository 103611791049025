import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { RankedBranchIdea, RankedBranchIdeas } from "../../../types/fastApiTypes";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { Tables } from "../../../types/database.ts";
import {
  getMergedBeatsByBranchName,
  mergeBeatsAndBeatsheets,
  mergeBeatsAndBeatsheetsByBranch,
} from "../../../utils/valueObject.ts";
import { IdeaItem } from "./IdeaItem.tsx";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateIdeasProps {
  ideas: RankedBranchIdeas | null;
  status: string;
  generateIdeas: (payload: any) => void;
  selectedIdea: RankedBranchIdea | null;
  setSelectedIdea: React.Dispatch<React.SetStateAction<RankedBranchIdea | null>>;
  setIdeas: React.Dispatch<React.SetStateAction<RankedBranchIdeas | null>>;

  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
}

const GenerateBeatIdeas: React.FC<GenerateIdeasProps> = ({
  ideas,
  status,
  generateIdeas,
  selectedIdea,
  setSelectedIdea,
  setIdeas,
  beats,
  beatsheets,
  branches,
  characters,
  clickedBranch,
  clickedBeatsheet,
}) => {
  const memoizedIdeas = useMemo(() => ideas, [ideas]);
  const [ideaSeed, setIdeaSeed] = useState("");

  useEffect(() => {
    if (ideas === null && status === "" && clickedBeatsheet && clickedBranch) {
      const mergedBeats = mergeBeatsAndBeatsheetsByBranch(beats, beatsheets, branches);
      const beatsForClickedBranch = getMergedBeatsByBranchName(mergedBeats, clickedBranch.name);
      generateIdeas({
        idea: ideaSeed || "",
        beats: beatsForClickedBranch,
        branches: branches,
        characters: characters,
        clickedBranch: clickedBranch,
        clickedBeatsheet: mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]),
      });
    }
  }, [ideas, status]);

  const handleRegenerate = () => {
    setIdeas(null);
  };

  return (
    <>
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        Generate Ideas{" "}
        <SpokableButton color="light" className="ml-2" onClick={handleRegenerate}>
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      <div className="mt-2">
        {status !== "" && <LoadingSpinner />}
        {memoizedIdeas && (
          <>
            <div className="flex w-full space-x-2">
              <div className="flex-grow">
                <AutoResizeTextArea value={ideaSeed} onChange={(value) => setIdeaSeed(value)} />
              </div>
              <SpokableButton onClick={handleRegenerate}>Generate</SpokableButton>
            </div>
            <div className="bg-zinc-200 rounded-lg p-3 mt-4 max-h-[calc(90vh-300px)] overflow-y-auto">
              {memoizedIdeas.ranked_ideas.map((idea, index) => (
                <IdeaItem
                  key={index}
                  idea={idea}
                  setSelectedIdea={setSelectedIdea}
                  selectedIdea={selectedIdea}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GenerateBeatIdeas;
