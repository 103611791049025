import { useEffect, useState } from "react";
import { supabase } from "../../vendor/supabaseClient.ts";
import Waveform from "./WaveForm.tsx";

interface StorageFileAudioPlayerProps {
  bucketName: string;
  storyId: string;
  fileName: string;
}

export default function StorageFileAudioPlayer({
  bucketName,
  storyId,
  fileName,
}: StorageFileAudioPlayerProps) {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchAudioFile();
  }, [bucketName, storyId, fileName]);

  const fetchAudioFile = async () => {
    const { data } = await supabase.storage.from(bucketName).getPublicUrl(`${storyId}/${fileName}`);
    console.log("Waveform", data);
    if (data) setAudioUrl(data.publicUrl);
  };

  return <div>{audioUrl && <Waveform url={audioUrl} />}</div>;
}
