import { supabase, supabaseUrl } from "../vendor/supabaseClient.ts";
import { FILE_TYPE_IMAGE, FILE_TYPE_SOUND } from "../components/admin/StorageFileSelector.tsx";
import {
  FILE_EXTENSION_IMAGE,
  FILE_EXTENSION_VIDEO,
  IMAGE_TYPE_DEPTH_MAP,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_ORIGINAL,
  IMAGE_TYPE_WITHOUT_BG,
  VISUAL_TYPE_IMAGE,
  VISUAL_TYPE_VIDEO,
} from "../constants/constant.ts";

export function getSupabaseImageUrl(
  storyId: string | null | undefined,
  imageName: string | null | undefined,
  type = VISUAL_TYPE_IMAGE,
): string | null {
  if (!imageName) return null;
  if (type == VISUAL_TYPE_VIDEO)
    imageName = imageName.replace(FILE_EXTENSION_IMAGE, FILE_EXTENSION_VIDEO);
  return `${supabaseUrl}/storage/v1/object/public/image/${storyId}/${imageName}`;
}

export async function uploadToSupabaseData(
  storyId: string,
  blob: Blob,
  fileNameWithExtension: string,
  bucket: string = FILE_TYPE_SOUND,
): Promise<string | null> {
  try {
    const filePath = `${storyId}/${Date.now()}-${fileNameWithExtension}`;
    const { data, error } = await supabase.storage.from(bucket).upload(filePath, blob, {
      upsert: true,
    });

    if (error) {
      console.error("Error uploading file:", error);
      return null;
    }

    if (data !== null) {
      return data.path;
    }
  } catch (error) {
    console.error("Error fetching file from URL:", error);
  }

  return null;
}

export function base64ToMp3Blob(base64: string): Blob {
  // Decode the Base64 string
  const binaryString: string = atob(base64);

  // Create a Uint8Array from the binary string
  const bytes: Uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob with the Uint8Array
  return new Blob([bytes], { type: "audio/mpeg" });
}

export function getImageNameWithoutStoryIdAndFileExtension(imageNameWithStoryId: string) {
  return imageNameWithStoryId.split("/").pop()?.split(".").shift();
}

export function removeFileExtension(fileName: string) {
  if (fileName && fileName.includes(".")) {
    const parts = fileName.split(".");
    fileName = parts.shift() || fileName;
  }
  return fileName;
}

export async function uploadToSupabaseFileFromUrl(
  storyId: string,
  fileUrl: string,
  fileName: string,
): Promise<string | null> {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const filePath = `${storyId}/${fileName}`;

    const { data, error } = await supabase.storage.from(FILE_TYPE_IMAGE).upload(filePath, blob, {
      upsert: true,
    });

    if (error) {
      console.error("Error uploading file:", error);
      return null;
    }

    if (data !== null) {
      return data.path;
    }
  } catch (error) {
    console.error("Error fetching file from URL:", error);
  }

  return null;
}

export function getDepthMapUrl(url: string): string {
  //"http://127.0.0.1:54321/storage/v1/object/public/image/5923aff4-85a5-484b-a5fd-a032d56900b5/adad.png";
  const parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  if (lastPart.startsWith(IMAGE_TYPE_WITHOUT_BG)) {
    lastPart = lastPart.replace(IMAGE_TYPE_WITHOUT_BG, "");
  }
  lastPart = IMAGE_TYPE_DEPTH_MAP + lastPart;
  parts[parts.length - 1] = lastPart;
  return parts.join("/");
}

export async function doesObjectExistInStorage(objectName: string) {
  const { data, error } = await supabase.storage.from(FILE_TYPE_IMAGE).download(objectName); // The expiry time is set to 1 second

  if (error) {
    console.error("Error fetching signed URL:", error);
    return false;
  }

  return !!data;
}

export async function downloadImageFromSupabase(fileNameWithStory: string) {
  const { data, error } = await supabase.storage.from(FILE_TYPE_IMAGE).download(fileNameWithStory);

  if (error) {
    console.error("Error fetching signed URL:", error);
    return null;
  }

  return data;
}

export function generateMediaFileName(
  baseObjectUrl: string,
  fileType: string,
  emotionName: string = "",
  isVideo: boolean = false,
): string {
  const fileExtension = isVideo ? FILE_EXTENSION_VIDEO : FILE_EXTENSION_IMAGE;
  baseObjectUrl = removeFileExtension(baseObjectUrl);

  const prefix = fileType === IMAGE_TYPE_ORIGINAL ? "" : fileType;
  const emotion = fileType === IMAGE_TYPE_EMOTION_VIDEO ? emotionName : "";

  return `${prefix}${emotion}${baseObjectUrl}${fileExtension}`;
}
