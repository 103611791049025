import { useRecoilState } from "recoil";
import { clickedCatalogueImageState, modalState } from "./State.ts";
import React, { useRef } from "react";
import { Tables } from "../../types/database.ts";
import { catalogueSelectedStoryState } from "../../states/catalogueState.ts";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import { computeRecommendationPercentage, formatDuration } from "../../utils/math.ts";
import { getRatingValue } from "../../constants/rating.ts";
import { Tag } from "../admin/SpokableTagSelector.tsx";

interface Props {
  story: Tables<"blueprint_stories_with_ratings">;
}

function Thumbnail({ story }: Props) {
  const [, setShowModal] = useRecoilState(modalState);
  const [, setCurrentStory] = useRecoilState(catalogueSelectedStoryState);
  const [, setClickedImageBoundingRec] = useRecoilState(clickedCatalogueImageState);
  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <div
      className="relative col-span h-[12vw] group font-soehn"
      onClick={() => {
        if (!imgRef.current) return;
        console.log(imgRef.current.getBoundingClientRect());
        setClickedImageBoundingRec(imgRef.current.getBoundingClientRect());
        setCurrentStory(story);
        setShowModal(true);
      }}
    >
      <div className="overflow-hidden h-[12vw] rounded-md border border-brand-yellow">
        <img
          src={getSupabaseImageUrl(story?.id, story?.background_image) || ""}
          className="cursor-pointer object-cover transition duration-300 w-full
          shadow-2xl rounded-md scale-110 group-hover:opacity-90 sm:group-hover:opacity-0 h-[12vw]"
          alt="thumbnail"
        />
        <p
          className=" cursor-pointer absolute bottom-4 left-6 text-1xl w-[50%]
        lg:text-2xl font-bold drop-shadow-xl uppercase"
          style={{ color: story?.text_rgba_color || "white" }}
        >
          {story?.name}
        </p>
      </div>

      <div
        className="absolute top-0 transition duration-300 z-10 invisible sm:visible w-full
      scale-100 opacity-0 group-hover:scale-125 group-hover:opacity-100 h-[12vw] shadow-2xl"
      >
        <div className="overflow-hidden border border-brand-yellow rounded-t-md h-[12vw]">
          <img
            ref={imgRef}
            src={getSupabaseImageUrl(story?.id, story?.background_image) || ""}
            className="cursor-pointer rounded-t-md duration-1000 transition object-cover
            w-full scale-110 group-hover:opacity-100 group-hover:scale-100 delay-300 h-[12vw]"
            alt="thumbnail"
          />
          <p
            className=" cursor-pointer absolute bottom-4 left-6 text-1xl
          w-[50%] lg:text-2xl font-bold drop-shadow-xl uppercase"
            style={{ color: story?.text_rgba_color || "white" }}
          >
            {story?.name}
          </p>
        </div>
        <div
          className="cursor-pointer  z-10 p-2 lg:p-4 absolute w-full transition shadow-md
           rounded-b-md -translate-y-1 border-b border-l border-r border-brand-yellow bg-black"
        >
          <div
            className="flex items-center text-sm space-x-4 p-2 bg-black text-white
          rounded-lg font-nunito"
          >
            <div>
              <div className="text-brand-yellow font-bold">
                {`${computeRecommendationPercentage(story?.positive_count, story?.negative_count)}% Recommended`}
              </div>
              <div className="flex items-center space-x-3 mt-2">
                {story?.esbr_rating && (
                  <span className="bg-gray-700 px-2 py-1 rounded">
                    {getRatingValue(story?.esbr_rating)}
                  </span>
                )}
                <span>{`${formatDuration(story?.estimated_minute_duration || 0)} (Approx)`} </span>
                <span className="bg-gray-700 px-2 py-1 rounded">HD</span>
              </div>
              <div className="flex space-x-3 mt-2">
                {story?.genre_tags &&
                  Array.isArray(story?.genre_tags) &&
                  story?.genre_tags.map((category, index) => (
                    <React.Fragment key={index}>
                      <span className="text-gray-400">{(category as unknown as Tag).value}</span>
                      {story?.genre_tags &&
                        Array.isArray(story?.genre_tags) &&
                        index < story.genre_tags.length - 1 && <span>·</span>}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thumbnail;
