import React, { useState } from "react";
import { Scene, Scenes } from "../../../types/fastApiTypes";
import { supabase } from "../../../vendor/supabaseClient.ts";
import { Tables, TablesInsert } from "../../../types/database.ts";
import { DialogTitle } from "../../catalyst/dialog.tsx";
import { SpokableButton } from "../SpokableButton.tsx";

export interface SaveAllProps {
  story: Tables<"blueprint_stories"> | null;
  clickedBranch: Tables<"blueprint_branches"> | undefined | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | undefined | null;
  handleClose: () => void;
  scenes: Scenes | null;
}

const SaveAll: React.FC<SaveAllProps> = ({
  scenes,
  story,
  clickedBranch,
  clickedBeatsheet,
  handleClose,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const saveAll = async () => {
    if (!story) return;

    createScenes();
    handleClose();
  };

  async function createScenes() {
    if (!scenes) return;
    scenes.scenes.map((scene, index) => createScene(scene, index));
  }

  async function createScene(scene: Scene, index: number) {
    if (!clickedBranch) return;
    if (!clickedBeatsheet) return;
    const sceneData: TablesInsert<"blueprint_scenes"> = {
      beatsheet_id: clickedBeatsheet?.id,
      branch_id: clickedBranch.id,
      character_development_contribution: scene.character_development_contribution,
      conflict_resolution: scene.conflict_resolution,
      main_conflict: scene.main_conflict,
      name: scene.name,
      objectives: scene.objectives,
      characters: scene.characters,
      plot_contribution: scene.plot_contribution,
      possible_branches: scene.possible_branches,
      scene_date: scene.scene_date,
      scene_location: scene.scene_location,
      scene_order: index,
      scene_summary: scene.scene_summary,
      story_id: story?.id,
      traps: scene.traps,
      wining_flow: scene.wining_flow,
    };
    const { data, error } = await supabase
      .from("blueprint_scenes")
      .insert(sceneData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error inserting scenes: " + error.message);
    } else {
      setErrorMessage("Saved!");
      return data;
    }
  }

  return (
    <>
      <DialogTitle as="h2" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Complete process
      </DialogTitle>
      <div className="flex">
        <div className="flex-grow">
          {errorMessage && <div>{errorMessage}</div>}
          <SpokableButton onClick={saveAll} className="mt-6 mb-6">
            Save all
          </SpokableButton>
        </div>
      </div>
    </>
  );
};

export default SaveAll;
