import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tables } from "../../types/database.ts";
import { supabase } from "../../vendor/supabaseClient.ts";
import { BRANCH_NAME_MAIN, PAGE_BEAT } from "../../constants/constant.ts";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import { Card, CardContent } from "../../components/admin/Card.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout.tsx";
import { SparklesIcon, TrashIcon } from "@heroicons/react/16/solid";
import GenerateBeatModal from "../../components/admin/generateBeatsModal/GenerateBeatModal.tsx";
import {
  modalBeatGenerateState,
  modalChaptersGenerateState,
  modalScenesGenerateState,
} from "../../states/ModalState.ts";
import { useRecoilState } from "recoil";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table.tsx";
import GenerateScenesModal from "../../components/admin/generateScenesModal/GenerateScenesModal.tsx";
import Tooltip from "../../components/Tooltip.tsx";
import GenerateChaptersModal from "../../components/admin/generateChaptersModal/GenerateChaptersModal.tsx";
import OverlayPanel from "../../components/admin/OverlayPanel.tsx";
import ChapterList from "./ChapterList.tsx";
import { unSnakeCase } from "../../utils/stringUtil.ts";
import DynamicForm from "../../components/admin/DynamicForm.tsx";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import { getYear } from "date-fns";

function BeatsDetail() {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);
  const [beats, setBeats] = useState<Tables<"blueprint_beats">[]>([]);
  const [branches, setBranches] = useState<Tables<"blueprint_branches">[]>([]);
  const [characters, setCharacters] = useState<Tables<"blueprint_characters">[]>([]);
  const [beatSheets, setBeatSheets] = useState<Tables<"blueprint_beatsheets">[]>([]);
  const [scenes, setScenes] = useState<Tables<"blueprint_scenes">[]>([]);
  const [branchTransitions, setBranchTransitions] = useState<
    Tables<"blueprint_branch_transitions">[]
  >([]);
  const [chapters, setChapters] = useState<Tables<"blueprint_chapters">[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const [isModalBeatGenerateOpen, setModalBeatGenerateOpen] =
    useRecoilState(modalBeatGenerateState);
  const [isModalScenesGenerateOpen, setModalScenesGenerateOpen] =
    useRecoilState(modalScenesGenerateState);
  const [isModalChaptersGenerateOpen, setModalChaptersGenerateOpen] = useRecoilState(
    modalChaptersGenerateState,
  );
  const [clickedBranch, setClickedBranch] = useState<Tables<"blueprint_branches"> | null>(null);
  const [clickedBeatSheet, setClickedBeatSheet] = useState<Tables<"blueprint_beatsheets"> | null>(
    null,
  );

  const [clickedScene, setClickedScene] = useState<Tables<"blueprint_scenes"> | null>(null);
  const [sceneInOverlay, setSceneInOverlay] = useState<Tables<"blueprint_scenes"> | null>(null);
  const [isOverlayChaptersOpen, setIsOverlayChaptersOpen] = useState(false);
  const [isOverlaySceneOpen, setIsOverlaySceneOpen] = useState(false);
  const [showChaptersForSceneId, setShowChaptersForSceneId] = useState<string | null>(null);

  useEffect(() => {
    stopBackgroundMusic();
    fetchStory();
    fetchAllData();
  }, [isModalBeatGenerateOpen, isModalScenesGenerateOpen, isModalChaptersGenerateOpen]);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
    }
  }

  async function fetchAllData() {
    if (storyId == undefined) return;
    const [
      beatsData,
      branchesData,
      beatSheetsData,
      charactersData,
      branchTransitionsData,
      scenesData,
      chaptersData,
    ] = await Promise.all([
      supabase.from("blueprint_beats").select("*").order("beat_order"),
      supabase.from("blueprint_branches").select("*").eq("story_id", storyId),
      supabase.from("blueprint_beatsheets").select("*").eq("story_id", storyId),
      supabase.from("blueprint_characters").select("*").eq("blueprint_story_id", storyId),
      supabase.from("blueprint_branch_transitions").select("*").eq("story_id", storyId),
      supabase.from("blueprint_scenes").select("*").eq("story_id", storyId).order("scene_order"),
      supabase.from("blueprint_chapters").select("*").eq("blueprint_story_id", storyId),
    ]);

    setBeats(beatsData.data || []);
    setBranches(branchesData.data || []);
    setBeatSheets(beatSheetsData.data || []);
    setCharacters(charactersData.data || []);
    setBranchTransitions(branchTransitionsData.data || []);
    setScenes(scenesData.data || []);
    setChapters(chaptersData.data || []);
  }

  const generateNewBranch = (
    branch: Tables<"blueprint_branches">,
    beatsheet: Tables<"blueprint_beatsheets"> | undefined,
  ) => {
    if (!beatsheet) return;
    setClickedBranch(branch);
    setClickedBeatSheet(beatsheet);
    setModalBeatGenerateOpen(true);
  };

  const generateScenes = (
    branch: Tables<"blueprint_branches">,
    beatsheet: Tables<"blueprint_beatsheets"> | undefined,
  ) => {
    if (!beatsheet) return;
    setClickedBranch(branch);
    setClickedBeatSheet(beatsheet);
    setModalScenesGenerateOpen(true);
  };

  const generateChapters = (scene: Tables<"blueprint_scenes">) => {
    if (!scene) return;
    setClickedScene(scene);
    setModalChaptersGenerateOpen(true);
  };

  const deleteBranch = async (branchId: string) => {
    if (window.confirm("Are you sure you want to delete this branch?")) {
      const { error } = await supabase.from("blueprint_branches").delete().eq("id", branchId);

      if (error) {
        setErrorMessage("Error deleting branch: " + error.message);
      } else {
        // Refresh the branches after deletion
        fetchAllData();
      }
    }
  };
  const deleteScene = async (sceneId: string) => {
    if (window.confirm("Are you sure you want to delete this scene?")) {
      const { error } = await supabase.from("blueprint_scenes").delete().eq("id", sceneId);

      if (error) {
        setErrorMessage("Error deleting scene: " + error.message);
      } else {
        // Refresh the branches after deletion
        fetchAllData();
      }
    }
  };

  async function updateScene(scene: Tables<"blueprint_scenes"> | null) {
    if (!scene) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...sceneWithoutId } = scene;

    const { error } = await supabase
      .from("blueprint_scenes")
      .update(sceneWithoutId)
      .eq("id", scene.id);

    if (error) {
      setErrorMessage("Error updating scene: " + error.message);
    } else {
      setErrorMessage("Saved! ");
    }
  }

  const renderCellContent = (
    beat: Tables<"blueprint_beats">,
    branch: Tables<"blueprint_branches">,
  ) => {
    const beatSheet = beatSheets.find((bs) => bs.beat_id === beat.id && bs.branch_id === branch.id);

    const transition = branchTransitions.find(
      (bt) => bt.to_beat_id === beatSheet?.id && bt.to_branch_id === branch.id,
    );

    const fromBranch = branches.find((b) => b.id === transition?.from_branch_id);

    const linkedScenes = scenes.filter((scene) => scene.beatsheet_id === beatSheet?.id);

    return (
      <div className="relative">
        <div className="group/beat">
          {transition && (
            <div className="bg-black text-white p-2 mb-2 text-sm">
              <p>From: {fromBranch?.name}</p>
              <p>{transition.transition_description}</p>
            </div>
          )}
          <div className="mb-2 overflow-y-auto max-h-32 whitespace-normal">
            {beatSheet?.description}
          </div>

          {linkedScenes.length == 0 && (
            <SpokableButton
              onClick={() => generateScenes(branch, beatSheet)}
              className="mt-2 opacity-0 group-hover/beat:opacity-100 transition-opacity duration-200 absolute bottom-2"
            >
              <SparklesIcon className="mr-2 h-4 w-4" />
              Scenes
            </SpokableButton>
          )}
          <SpokableButton
            onClick={() => generateNewBranch(branch, beatSheet)}
            className="ml-2 mt-2 opacity-0 group-hover/beat:opacity-100 transition-opacity duration-200 absolute bottom-2"
          >
            <SparklesIcon className="mr-2 h-4 w-4" />
            Branch
          </SpokableButton>
        </div>
        {linkedScenes.length > 0 && (
          <div className="mt-4 border-t pt-2">
            <h4 className="font-semibold mb-2">Scenes:</h4>
            {linkedScenes.map((scene) => {
              const linkedChaptersCount = chapters.filter(
                (chapter) => chapter.scene_id === scene.id,
              ).length;
              return (
                <Tooltip key={scene.id} content={scene.scene_summary}>
                  <div key={scene.id} className="flex items-center mb-1 group/scene">
                    <span
                      className="hover:underline cursor-pointer"
                      onClick={() => {
                        setIsOverlaySceneOpen(true);
                        setSceneInOverlay(scene);
                      }}
                    >
                      {scene.name}
                    </span>
                    {linkedChaptersCount > 0 && (
                      <strong
                        className="ml-4 cursor-pointer hover:underline"
                        onClick={() => {
                          setIsOverlayChaptersOpen(true);
                          setShowChaptersForSceneId(scene.id);
                        }}
                      >
                        {linkedChaptersCount} moments
                      </strong>
                    )}
                    {linkedChaptersCount == 0 && (
                      <SpokableButton
                        className="ml-6 opacity-0 group-hover/scene:opacity-100 transition-opacity duration-200"
                        onClick={() => generateChapters(scene)}
                      >
                        <SparklesIcon className="h-4 w-4" />
                        Moments
                      </SpokableButton>
                    )}
                    <SpokableButton
                      className="ml-6 opacity-0 group-hover/scene:opacity-100 transition-opacity duration-200"
                      onClick={() => deleteScene(scene.id)}
                    >
                      <TrashIcon className="h-4 w-4 text-red-500" />
                    </SpokableButton>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalSideNavLayout activePageId={PAGE_BEAT} storyId={storyId || ""}>
      <div>
        <div className="top-section sticky top-0 backdrop-blur-xl z-50  p-6">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}
          <div className="flex gap-6">
            {story.background_image && (
              <img
                alt={"story image"}
                key={story.id}
                className="size-20 flex-shrink-0"
                src={
                  getSupabaseImageUrl(story.id, story.background_image) ||
                  `https://api.dicebear.com/9.x/shapes/svg?seed=${story.id}`
                }
              />
            )}
            <div className="flex flex-col justify-between w-full">
              <div>
                <div className="text-sm text-gray-500 mb-2">
                  {story.created_at ? getYear(story.created_at) : ""}
                </div>
                <h1 className="text-5xl font-bold mb-2">{story.name}</h1>
                <div className="text-xl mb-4">{}</div>
              </div>
            </div>
          </div>
        </div>
        <Card isFullWidth={true}>
          <CardContent>
            <div className="overflow-x-auto">
              <Table className="w-full" grid>
                <TableHead>
                  <TableRow>
                    <TableHeader className="w-[200px] min-w-[200px]">Beat</TableHeader>
                    {branches.map((branch) => (
                      <TableHeader
                        key={branch.id}
                        className={`w-[calc((100%-200px)/${branches.length})] group`}
                      >
                        <div className="flex items-center">
                          <span>{branch.name}</span>
                          {branch.name != BRANCH_NAME_MAIN && (
                            <SpokableButton
                              className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              onClick={() => deleteBranch(branch.id)}
                            >
                              <TrashIcon className="h-4 w-4 text-red-500" />
                            </SpokableButton>
                          )}
                        </div>
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beats.map((beat) => (
                    <TableRow key={beat.id + branches.length}>
                      <TableCell className="w-[200px] min-w-[200px] font-medium align-top pt-4">
                        {unSnakeCase(beat.name)}
                      </TableCell>
                      {branches.map((branch) => {
                        return (
                          <TableCell
                            key={`${beat.id}-${branch.id}`}
                            className={`w-[calc((100%-200px)/${branches.length})] relative group align-top pt-4`}
                          >
                            {renderCellContent(beat, branch)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </CardContent>
        </Card>
      </div>
      <OverlayPanel
        isOpen={isOverlayChaptersOpen}
        onClose={() => {
          setIsOverlayChaptersOpen(false);
          setShowChaptersForSceneId(null);
        }}
      >
        <ChapterList
          story={story}
          chapters={chapters}
          filterBySceneId={showChaptersForSceneId}
          onChapterUpdateOrDeleted={() => fetchAllData()}
        />
      </OverlayPanel>
      <OverlayPanel
        isOpen={isOverlaySceneOpen}
        onClose={() => {
          updateScene(sceneInOverlay);
          setIsOverlaySceneOpen(false);
          setSceneInOverlay(null);
          fetchAllData();
        }}
      >
        {sceneInOverlay && (
          <div className="p-20 pt-10">
            <DynamicForm
              data={sceneInOverlay}
              setData={setSceneInOverlay}
              filterDates={true}
              filterIds={true}
            />
          </div>
        )}
      </OverlayPanel>
      <GenerateBeatModal
        story={story}
        branches={branches}
        characters={characters}
        beats={beats}
        beatsheets={beatSheets}
        clickedBranch={clickedBranch}
        clickedBeatsheet={clickedBeatSheet}
      />
      <GenerateScenesModal
        story={story}
        beats={beats}
        beatsheets={beatSheets}
        branches={branches}
        characters={characters}
        clickedBranch={clickedBranch}
        clickedBeatsheet={clickedBeatSheet}
        branchTransitions={branchTransitions}
      />
      <GenerateChaptersModal story={story} characters={characters} scene={clickedScene} />
    </GlobalSideNavLayout>
  );
}

export default BeatsDetail;
