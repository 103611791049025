import React, { useEffect, useState } from "react";
import axios from "axios";
import { ImageVariation, ImageVariations } from "../../../types/fastApiTypes.ts";
import {
  downloadImageFromSupabase,
  generateMediaFileName,
  getImageNameWithoutStoryIdAndFileExtension,
  uploadToSupabaseFileFromUrl,
} from "../../../utils/mediaUtil.ts";
import {
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_DEPTH_MAP,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  IMAGE_TYPE_ORIGINAL,
  IMAGE_TYPE_WITHOUT_BG,
  IMAGE_VARIATION_ENDPOINT,
} from "../../../constants/constant.ts";
import { DEV_FAST_API_HTTP } from "../../../vendor/config.ts";
import { Card, CardHeader, CardImage, CardText, CardVideo } from "../Card.tsx";
import { Text } from "../../catalyst/text";
import { SpokableButton } from "../SpokableButton.tsx";
import { debug } from "../../../utils/debugUtil.ts";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import FormField from "../FormField.tsx";

export interface BluePrintImageToBeSaved {
  image_url: string;
  image_type: string;
  has_version_without_background?: boolean;
  has_depth_map?: boolean;
  has_original?: boolean;
  has_emotion_videos?: boolean;
  has_cinematic_videos?: boolean;
}

interface GenerateImageVariationsProps {
  storyId: string;
  baseImageUrlWithStory: string | null;
  number_of_variations: number;
  compute_depth_map: boolean;
  remove_background: boolean;
  compute_emotion_videos: boolean;
  compute_cinematic_videos: boolean;
  onVariationsCompleted: (imageToBeSaved: BluePrintImageToBeSaved) => void;
  videoPrompt?: string | undefined | null;
  videoEventPrompt?: string | undefined | null;
}

export const GenerateImageVariations: React.FC<GenerateImageVariationsProps> = ({
  baseImageUrlWithStory,
  storyId,
  onVariationsCompleted,
  number_of_variations = 0,
  compute_depth_map = true,
  compute_emotion_videos = false,
  compute_cinematic_videos = false,
  remove_background = false,
  videoPrompt,
  videoEventPrompt,
}) => {
  const [bluePrintImageToBeSaved, setBluePrintImageToBeSaved] =
    useState<BluePrintImageToBeSaved | null>(null);
  const [status, setStatus] = useState("");
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [imageVariations, setImageVariations] = useState<ImageVariation[]>([]);
  const [baseImageFile, setBaseImageFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cinematicPrompt, setCinematicPrompt] = useState(videoPrompt || "");
  const [eventPrompt, setEventPrompt] = useState(videoEventPrompt || "");

  useEffect(() => {
    downloadImage();
  }, [baseImageUrlWithStory]);

  useEffect(() => {
    uploadVariationsToSupabase();
  }, [imageVariations]);

  async function downloadImage() {
    if (!baseImageUrlWithStory) return;

    console.log("baseImageUrl", baseImageUrlWithStory);
    const blob = await downloadImageFromSupabase(baseImageUrlWithStory);
    if (!blob) {
      console.log("image not found ", baseImageUrlWithStory);
      return;
    }
    const file = new File([blob], baseImageUrlWithStory, {
      type: "image/jpeg",
    });

    setBaseImageFile(file);
    setStatus("");
  }

  async function processImageVariations() {
    if (isProcessing || !baseImageFile) return;
    try {
      setIsProcessing(true);
      const URL = import.meta.env.VITE_FAST_API_HTTP || DEV_FAST_API_HTTP;
      const formData = new FormData();

      formData.append("file", baseImageFile);
      formData.append("number_of_variations", number_of_variations.toString());
      formData.append("compute_depth_map", compute_depth_map.toString());
      formData.append("remove_background", remove_background.toString());
      formData.append("compute_emotion_videos", compute_emotion_videos.toString());
      formData.append("compute_cinematic_videos", compute_cinematic_videos.toString());
      formData.append("video_prompt", cinematicPrompt);
      formData.append("video_event_prompt", eventPrompt);

      setStatus("Hardcore image processing in progress...");

      const response = await axios.post<ImageVariations>(URL + IMAGE_VARIATION_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Variations processed successfully:", response.data);

      setImageVariations(response.data.variations);
    } catch (error) {
      console.error("Error processing image:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Server response:", error.response.data);
      }
      setStatus(
        `Error processing image: ${error instanceof Error ? error.message : String(error)}`,
      );
      return { error: error instanceof Error ? error.message : String(error) };
    } finally {
      setIsProcessing(false);
    }
  }

  async function uploadVariationsToSupabase() {
    if (!baseImageUrlWithStory) return;
    const baseObjectUrl = getImageNameWithoutStoryIdAndFileExtension(baseImageUrlWithStory);
    if (!baseObjectUrl) return;
    const returnedImageObject: BluePrintImageToBeSaved = {
      image_url: generateMediaFileName(baseObjectUrl, IMAGE_TYPE_ORIGINAL),
      image_type: IMAGE_TYPE_ORIGINAL,
      has_original: true,
    };

    if (canBeSaved && imageVariations.length > 0) {
      setStatus("Uploading...");
      debug(imageVariations);
      const variation = imageVariations[0];

      if (variation.depth_map) {
        const depthMapUrl = await uploadToSupabaseFileFromUrl(
          storyId,
          variation.depth_map,
          generateMediaFileName(baseObjectUrl, IMAGE_TYPE_DEPTH_MAP),
        );
        if (depthMapUrl) returnedImageObject.has_depth_map = true;
      }

      if (variation.without_bg) {
        const noBackgroundUrl = await uploadToSupabaseFileFromUrl(
          storyId,
          variation.without_bg,
          generateMediaFileName(baseObjectUrl, IMAGE_TYPE_WITHOUT_BG),
        );
        if (noBackgroundUrl) returnedImageObject.has_version_without_background = true;
      }

      if (variation.emotion_videos) {
        variation.emotion_videos.map(async (emotion_video) => {
          await uploadToSupabaseFileFromUrl(
            storyId,
            emotion_video.url,
            generateMediaFileName(
              baseObjectUrl,
              IMAGE_TYPE_EMOTION_VIDEO,
              emotion_video.emotion,
              true,
            ),
          );
        });
        returnedImageObject.has_emotion_videos = true;
      }

      if (variation.cinematic_video) {
        await uploadToSupabaseFileFromUrl(
          storyId,
          variation.cinematic_video,
          generateMediaFileName(baseObjectUrl, IMAGE_TYPE_CINEMATIC_VIDEO, "", true),
        );
        returnedImageObject.has_cinematic_videos = true;
      }
      console.log("variation.event_video", variation.event_video);
      if (variation.event_video) {
        await uploadToSupabaseFileFromUrl(
          storyId,
          variation.event_video,
          generateMediaFileName(baseObjectUrl, IMAGE_TYPE_EVENT_VIDEO, "", true),
        );
        returnedImageObject.has_cinematic_videos = true;
      }
    }

    setStatus("");
    setCanBeSaved(true);
    setBluePrintImageToBeSaved(returnedImageObject);
  }

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      {compute_cinematic_videos && (
        <>
          <FormField label={"Video when character not speaking"}>
            <AutoResizeTextArea
              className="mb-4"
              value={cinematicPrompt}
              onChange={(e) => setCinematicPrompt(e)}
            />
          </FormField>
          <FormField label={"Video when objective completed"}>
            <AutoResizeTextArea value={eventPrompt} onChange={(e) => setEventPrompt(e)} />
          </FormField>
        </>
      )}
      <SpokableButton onClick={processImageVariations} className="mb-4" disabled={isProcessing}>
        <SparklesIcon className="mr-2 mb-4" />
        Generate videos
      </SpokableButton>
      {canBeSaved && (
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
          {imageVariations.map((variation, index) => (
            <React.Fragment key={index}>
              <li>
                <Card className="aspect-square">
                  <CardHeader className="h-full">
                    <div className="relative w-full h-full">
                      <CardImage src={variation.original} />
                    </div>
                    <CardText>Original</CardText>
                  </CardHeader>
                </Card>
              </li>
              {variation.without_bg && (
                <li>
                  <Card className="aspect-square">
                    <CardHeader className="h-full">
                      <div className="relative w-full h-full">
                        <CardImage src={variation.without_bg} />
                      </div>
                      <CardText>Without Background</CardText>
                    </CardHeader>
                  </Card>
                </li>
              )}
              {variation.depth_map && (
                <li>
                  <Card className="aspect-square">
                    <CardHeader className="h-full">
                      <div className="relative w-full h-full">
                        <CardImage src={variation.depth_map} />
                      </div>
                      <CardText>Depth Map</CardText>
                    </CardHeader>
                  </Card>
                </li>
              )}
              {variation.emotion_videos &&
                variation.emotion_videos.map((emotionVideo, videoIndex) => (
                  <li key={`${videoIndex}`}>
                    <Card className="aspect-square">
                      <CardHeader className="h-full">
                        <div className="relative w-full h-full">
                          <CardVideo
                            src={emotionVideo.url}
                            className="absolute inset-0 object-cover"
                          />
                        </div>
                        <CardText>{emotionVideo.emotion}</CardText>
                      </CardHeader>
                    </Card>
                  </li>
                ))}
              {variation.cinematic_video && (
                <li>
                  <Card className="aspect-square">
                    <CardHeader className="h-full">
                      <div className="relative w-full h-full">
                        <CardVideo
                          src={variation.cinematic_video}
                          className="absolute inset-0 object-cover"
                        />
                      </div>
                      <CardText>Cinematic Video</CardText>
                    </CardHeader>
                  </Card>
                </li>
              )}
              {variation.event_video && (
                <li>
                  <Card className="aspect-square">
                    <CardHeader className="h-full">
                      <div className="relative w-full h-full">
                        <CardVideo
                          src={variation.event_video}
                          className="absolute inset-0 object-cover"
                        />
                      </div>
                      <CardText>Event Video</CardText>
                    </CardHeader>
                  </Card>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
      <Text>{status}</Text>
      {bluePrintImageToBeSaved && canBeSaved && imageVariations.length != 0 && (
        <SpokableButton
          className="align-bottom mt-12"
          onClick={() => onVariationsCompleted(bluePrintImageToBeSaved)}
          disabled={imageVariations.length === 0}
        >
          Add videos above
        </SpokableButton>
      )}
    </div>
  );
};
