// components/imageEffects/CustomEffect.tsx
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import * as THREE from "three";
import { isMobile } from "react-device-detect";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { getDepthMapUrl } from "../../utils/mediaUtil.ts";
import { useRecoilState } from "recoil";
import { lastImageEffectConfigState } from "../../states/effectState.ts";
import { buildControlsIfAdmin } from "../../utils/imageEffect.ts";

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

interface ThreeDimOnMoveEffectProps {
  composer: EffectComposer | null;
  texture: THREE.Texture;
  depthMapTextureURL: string;
  params?: any;
}

const ParallaxOnMoveEffect: React.FC<ThreeDimOnMoveEffectProps> = ({
  composer,
  texture,
  depthMapTextureURL,
  params,
}) => {
  const { size } = useThree();
  const uMouse = useRef(new THREE.Vector2(-10, -10));
  const uScale = useRef(0);
  const uShift = useRef(0);
  const targetScale = useRef(0);
  const targetShift = useRef(0);
  let displacementTexture: THREE.Texture | null = null;
  displacementTexture = useLoader(TextureLoader, getDepthMapUrl(depthMapTextureURL));

  const shaderParams =
    params ||
    buildControlsIfAdmin({
      scaleIntensity: {
        value: 0.5,
        min: 0,
        max: 3,
        step: 0.01,
      },
      shiftIntensity: {
        value: 0.05,
        min: 0,
        max: 1,
        step: 0.01,
      },
      lerpFactor: {
        value: 0.1,
        min: 0,
        max: 1,
        step: 0.01,
      },
    });
  const [, setShaderParams] = useRecoilState(lastImageEffectConfigState);
  const memoizedSetShaderParams = useCallback(() => {
    setShaderParams(shaderParams);
  }, [JSON.stringify(shaderParams), setShaderParams]);

  useEffect(() => {
    memoizedSetShaderParams();
  }, [memoizedSetShaderParams]);

  const customPass = useMemo(() => {
    const customEffect = {
      uniforms: {
        tDiffuse: { value: texture },
        tDepth: { value: displacementTexture },
        resolution: { value: new THREE.Vector2(1, size.height / size.width) },
        uMouse: { value: uMouse.current },
        uVelo: { value: 0 },
        uScale: { value: uScale.current },
        uShift: { value: uShift.current },
        scaleIntensity: { value: shaderParams.scaleIntensity },
        shiftIntensity: { value: shaderParams.shiftIntensity },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform sampler2D tDiffuse;
        uniform sampler2D tDepth;
        uniform vec2 resolution;
        varying vec2 vUv;
        uniform vec2 uMouse;
        uniform float uScale;
        uniform float uShift;
        uniform float scaleIntensity;
        uniform float shiftIntensity;

        void main() {
          vec2 newUV = vUv;

          // Sample the depth map with a larger area
          float depth = texture2D(tDepth, vUv).r;
          float depthL = texture2D(tDepth, vUv + vec2(-0.01, 0.0)).r;
          float depthR = texture2D(tDepth, vUv + vec2(0.01, 0.0)).r;
          float depthT = texture2D(tDepth, vUv + vec2(0.0, -0.01)).r;
          float depthB = texture2D(tDepth, vUv + vec2(0.0, 0.01)).r;
          
          // Calculate the average depth
          float avgDepth = (depth + depthL + depthR + depthT + depthB) / 5.0;

          // Calculate the displacement based on the average depth and mouse position
          vec2 displacement = (uMouse - vec2(0.5)) * avgDepth * uScale * scaleIntensity;

          // Apply the displacement to the UV coordinates
          newUV += displacement;

          // Sample the color from the displaced coordinates
          vec4 color = texture2D(tDiffuse, newUV);

          gl_FragColor = color;
        }
      `,
    };

    return new ShaderPass(customEffect);
  }, [
    size,
    texture,
    displacementTexture,
    shaderParams.scaleIntensity,
    shaderParams.shiftIntensity,
  ]);

  useEffect(() => {
    if (composer) {
      composer.addPass(customPass);
    }

    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
      targetScale.current = (clientX - window.innerWidth / 2) / (window.innerWidth / 2);
      targetShift.current = (clientY - window.innerHeight / 2) / (window.innerHeight / 2);
    };

    const handleTouchMove = (event: TouchEvent) => {
      const { clientX, clientY } = event.touches[0];
      uMouse.current.x = clientX / window.innerWidth;
      uMouse.current.y = 1 - clientY / window.innerHeight;
      targetScale.current = (clientX - window.innerWidth / 2) / (window.innerWidth / 2);
      targetShift.current = (clientY - window.innerHeight / 2) / (window.innerHeight / 2);
    };

    const resetEffect = () => {
      targetScale.current = 0;
      targetShift.current = 0;
    };

    if (isMobile) {
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("touchend", resetEffect);
    } else {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", resetEffect);
    }

    return () => {
      if (composer) {
        composer.removePass(customPass);
      }

      if (isMobile) {
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("touchend", resetEffect);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", resetEffect);
      }
    };
  }, [composer, customPass]);

  useFrame((_, _delta) => {
    uScale.current = lerp(
      uScale.current,
      targetScale.current * shaderParams.shiftIntensity,
      shaderParams.lerpFactor,
    );
    uShift.current = lerp(
      uShift.current,
      targetShift.current * shaderParams.shiftIntensity,
      shaderParams.lerpFactor,
    );

    customPass.uniforms.uMouse.value = uMouse.current;
    customPass.uniforms.uScale.value = uScale.current;
    customPass.uniforms.uShift.value = uShift.current;
  }, 1);

  return null;
};

export default ParallaxOnMoveEffect;
