import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import GenerateIdeas from "./GenerateIdeas.tsx";
import ReviewIdea from "./ReviewIdeas.tsx";
import GenerateLogLine from "./GenerateLogLine.tsx";
import GenerateOnePager from "./GenerateOnePager.tsx";
import GenerateCharacters from "./GenerateCharacters.tsx";
import GenerateBeatSheet from "./GenerateBeatSheet.tsx";
import SaveAll from "./SaveAll.tsx";
import { Tables } from "../../../types/database.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateModalProps {
  story: Tables<"blueprint_stories">;
}

const GenerateStoryModal: React.FC<GenerateModalProps> = ({ story }) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    selectedIdea,
    setSelectedIdea,
    handleClose,
    ideas,
    setIdeas,
    ideasStatus,
    generateIdeas,
    logLine,
    logLineStatus,
    generateLogLine,
    setLogLine,
    onePager,
    onePagerStatus,
    generateOnePager,
    setOnePager,
    charactersSummary,
    charactersSummaryStatus,
    setCharactersSummary,
    generateCharactersSummary,
    beatSheet,
    beatSheetStatus,
    generateBeatSheet,
    setBeatSheet,
  } = useGenerateSteps();

  const maxStepIndex = 6;

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateIdeas
            ideas={ideas}
            status={ideasStatus}
            generateIdeas={generateIdeas}
            setSelectedIdea={setSelectedIdea}
            selectedIdea={selectedIdea}
            setIdeas={setIdeas}
          />
        );
      case 1:
        return <ReviewIdea selectedIdea={selectedIdea} setSelectedIdea={setSelectedIdea} />;
      case 2:
        return (
          <GenerateLogLine
            generateLogLine={generateLogLine}
            logLine={logLine}
            status={logLineStatus}
            setLogLine={setLogLine}
            selectedIdea={selectedIdea}
          />
        );
      case 3:
        return (
          <GenerateOnePager
            onePager={onePager}
            status={onePagerStatus}
            generateOnePager={generateOnePager}
            setOnePager={setOnePager}
            logline={logLine}
          />
        );
      case 4:
        return (
          <GenerateCharacters
            charactersSummary={charactersSummary}
            status={charactersSummaryStatus}
            setCharactersSummary={setCharactersSummary}
            onePager={onePager}
            generateCharacters={generateCharactersSummary}
          />
        );
      case 5:
        return (
          <GenerateBeatSheet
            beatSheet={beatSheet}
            status={beatSheetStatus}
            setBeatSheet={setBeatSheet}
            generateBeatSheet={generateBeatSheet}
            onePager={onePager}
            charactersSummary={charactersSummary}
          />
        );
      case 6:
        return (
          <SaveAll
            logLine={logLine}
            beatSheet={beatSheet}
            onePager={onePager}
            charactersSummary={charactersSummary}
            story={story}
            handleClose={handleClose}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-nunito" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {selectedIdea && stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {selectedIdea && stepIndex < maxStepIndex && (
                      <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateStoryModal;
