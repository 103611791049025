import {
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/16/solid";
import { InboxIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "../catalyst/navbar.tsx";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../catalyst/dropdown.tsx";
import { Avatar } from "../catalyst/avatar.tsx";
import { PropsWithChildren } from "react";
import SvgLogo from "../SvgLogo.tsx";
import { supabase } from "../../vendor/supabaseClient.ts";

const navItems = [
  { label: "Home", url: "/" },
  { label: "StoryMaker™", url: "/admin" },
];

function DropdownDivider() {
  return null;
}

type LayoutProps = PropsWithChildren<{}>;

export function Layout({ children }: LayoutProps) {
  return (
    <div className="relative">
      <Navbar
        className="backdrop-blur-xl sticky top-0  pl-20 pr-20 pt-1 z-10 text-white font-nunito"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <SvgLogo />
        <NavbarDivider className="max-lg:hidden bg-brand-yellow" />
        <NavbarSection className="max-lg:hidden">
          {navItems.map(({ label, url }) => (
            <NavbarItem key={label} href={url}>
              {label}
            </NavbarItem>
          ))}
        </NavbarSection>
        <NavbarSpacer />
        <NavbarSection>
          <NavbarItem href="/search" aria-label="Search">
            <MagnifyingGlassIcon color="white" className="text-white" />
          </NavbarItem>
          <NavbarItem href="/inbox" aria-label="Inbox">
            <InboxIcon color="white" className="text-white" />
          </NavbarItem>
          <Dropdown>
            <DropdownButton as={NavbarItem} className="cursor-pointer">
              <Avatar src="https://api.dicebear.com/8.x/notionists/svg?seed=nico" square />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
              <DropdownItem href="/my-profile">
                <UserIcon />
                <DropdownLabel>My profile</DropdownLabel>
              </DropdownItem>
              <DropdownItem href="/settings">
                <Cog8ToothIcon />
                <DropdownLabel>Settings</DropdownLabel>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem href="/privacy-policy">
                <ShieldCheckIcon />
                <DropdownLabel>Privacy policy</DropdownLabel>
              </DropdownItem>
              <DropdownItem href="/share-feedback">
                <LightBulbIcon />
                <DropdownLabel>Share feedback</DropdownLabel>
              </DropdownItem>
              <DropdownDivider />
              <DropdownItem onClick={() => supabase.auth.signOut()}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>Sign out</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarSection>
      </Navbar>
      <div className="relative z-0 transform bg-gradient-to-r from-black from-20% via-gray-900 via-60% to-black to-90%">
        {children}
      </div>
    </div>
  );
}
