import React, { useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { LoadingSpinner } from "../LoadingSpinner";
import DynamicForm from "../DynamicForm";
import { Divider } from "../../catalyst/divider";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { Scenes } from "../../../types/fastApiTypes";
import { Tables } from "../../../types/database.ts";
import { getMergedTransitions, mergeBeatsAndBeatsheets } from "../../../utils/valueObject.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateScenesProps {
  beats: Tables<"blueprint_beats">[];
  scenes: Scenes | null;
  status: string;
  setScenes: React.Dispatch<React.SetStateAction<Scenes | null>>;
  generateScenes: (payload: any) => void;
  characters: Tables<"blueprint_characters">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
  branches: Tables<"blueprint_branches">[];
  branchTransitions: Tables<"blueprint_branch_transitions">[] | null;
}

const GenerateScenesIdeas: React.FC<GenerateScenesProps> = ({
  beats,
  scenes,
  status,
  setScenes,
  generateScenes,
  characters,
  beatsheets,
  clickedBeatsheet,
  clickedBranch,
  branches,
  branchTransitions,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const memoizedScenes = useMemo(() => scenes, [scenes]);

  useEffect(() => {
    if (scenes === null && status === "" && clickedBeatsheet && clickedBranch) {
      const transitions = getMergedTransitions(
        beats,
        beatsheets,
        branchTransitions,
        clickedBeatsheet,
        branches,
      );

      generateScenes({
        characters: characters,
        one_pager: clickedBranch.one_pager,
        beatsheets: mergeBeatsAndBeatsheets(beats, beatsheets),
        clickedBeatsheet: mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]),
        branches: branches,
        branch_transitions: transitions,
      });
    }
  }, [scenes, characters, generateScenes]);

  const handleRegenerate = () => {
    if (characters) {
      setScenes(null);
    }
  };

  const scrollToCharacterSheet = (index: number) => {
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current.querySelector(`#character-sheet-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
    <>
      <Dialog.Title as="h3" className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Scenes
        <SpokableButton
          color="light"
          className="ml-2"
          isIconButton={true}
          onClick={handleRegenerate}
        >
          <ArrowPathIcon />
        </SpokableButton>
        <span className="text-sm ml-2"> {status}</span>
      </Dialog.Title>
      {memoizedScenes && (
        <div className="flex">
          <div className="w-48 pr-4 border-r border-gray-200">
            {memoizedScenes && (
              <ul className="space-y-1">
                {memoizedScenes.scenes.map((scene, index) => (
                  <li key={index}>
                    <button
                      onClick={() => scrollToCharacterSheet(index)}
                      className="w-full text-left text-sm text-zinc-600 hover:text-zinc-800 p-1 focus:text-zinc-800 focus:font-semibold"
                    >
                      {scene.name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex-grow pl-4">
            {status !== "" && <LoadingSpinner />}
            {memoizedScenes && (
              <div
                ref={scrollContainerRef}
                className="mt-4 max-h-[calc(90vh-300px)] overflow-y-auto"
              >
                {memoizedScenes.scenes.map((scene, index) => (
                  <div id={`character-sheet-${index}`} key={index}>
                    <DynamicForm
                      data={scene}
                      arrayKey={"scenes"}
                      index={index}
                      setData={setScenes}
                    />
                    <Divider className="mb-4" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateScenesIdeas;
