import React from "react";

interface MediaComponentProps {
  src: string;
  alt?: string;
  className?: string;
}

export const MediaComponent: React.FC<MediaComponentProps> = ({
  src,
  alt = "",
  className = "",
}) => {
  console.log(src);
  if (src.endsWith(".mp4")) {
    return (
      <video src={src} className={`${className} w-full h-full object-cover`} autoPlay loop muted />
    );
  }
  return <img src={src} alt={alt} className={`${className} w-full h-full object-cover`} />;
};
