import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, { Controls, Edge, MiniMap, Node, useEdgesState, useNodesState } from "reactflow";
import { useParams } from "react-router-dom";
import "reactflow/dist/style.css";
import { GlobalSideNavLayout } from "../../components/admin/GlobalSideNavLayout";
import { PAGE_CHAPTER } from "../../constants/constant";
import TransitionSidePanel from "../../components/admin/chapterGraph/TransitionsSidePanel";
import CustomChapterFlowNode from "../../components/admin/chapterGraph/CustomChapterFlowNode.tsx";
import { useChapterGraph } from "../../components/admin/chapterGraph/useChapterGraphData.ts";
import { useLayoutedElements } from "../../components/admin/chapterGraph/useLayoutCalculation.ts";
import OverlayPanel from "../../components/admin/OverlayPanel.tsx";

const nodeTypes = {
  custom: CustomChapterFlowNode,
};

const ChapterGraph: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [isOverlayChaptersOpen, setIsOverlayChaptersOpen] = useState(false);

  const {
    story,
    chapters,

    initialNodes,
    initialEdges,
    onConnect,
    onEdgeDelete,
    onNodeDelete,
    updateNode,
    updateEdge,
    errorMessage,
    isLoading,
    fetchingErrorMessage,
    refreshData,
  } = useChapterGraph(storyId);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { getLayoutedElements, redrawKey } = useLayoutedElements();

  useEffect(() => {
    if (initialNodes.length > 0 && initialEdges.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        initialNodes,
        initialEdges,
      );
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
  }, [initialNodes, initialEdges, getLayoutedElements, setNodes, setEdges]);

  const onNodeClick = useCallback((_event: React.MouseEvent, node: Node) => {
    setSelectedEdge(null);
    setSelectedNode(node);
    setIsOverlayChaptersOpen(true);
  }, []);

  const onEdgeClick = useCallback((_event: React.MouseEvent, edge: Edge) => {
    setSelectedNode(null);
    setSelectedEdge(edge);
    setIsOverlayChaptersOpen(true);
  }, []);

  const onPaneClick = useCallback(() => {
    setSelectedEdge(null);
    setSelectedNode(null);
    setIsOverlayChaptersOpen(false);
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (errorMessage) return <div>{errorMessage}</div>;
  if (fetchingErrorMessage) return <div>{fetchingErrorMessage}</div>;
  if (!story) return <div>No story</div>;
  if (!chapters) return <div>No chapters</div>;

  return (
    <GlobalSideNavLayout activePageId={PAGE_CHAPTER} storyId={storyId} padding="p-0">
      <div className="flex h-screen admin font-nunito">
        <div className="h-full w-full">
          <ReactFlow
            key={redrawKey}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onEdgeClick={onEdgeClick}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            onEdgesDelete={onEdgeDelete}
            onNodesDelete={onNodeDelete}
            nodeTypes={nodeTypes}
            fitView
          >
            <Controls />
            <MiniMap pannable zoomable />
          </ReactFlow>
        </div>
        <OverlayPanel
          isOpen={isOverlayChaptersOpen}
          onClose={() => {
            setIsOverlayChaptersOpen(false);
          }}
        >
          <TransitionSidePanel
            selectedChapterId={selectedNode?.id}
            selectedChapterTransitionId={selectedEdge?.id}
            onUpdateChapter={updateNode}
            onUpdateChapterTransition={updateEdge}
            story={story}
            chapters={chapters}
            onRefreshChapters={refreshData}
          />
        </OverlayPanel>
      </div>
    </GlobalSideNavLayout>
  );
};

export default ChapterGraph;
