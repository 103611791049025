import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { Tables } from "../../../types/database.ts";

import { Badge } from "../../catalyst/badge.tsx";
import { mergeBeatsAndBeatsheets } from "../../../utils/valueObject.ts";
import GenerateScenesIdeas from "./GenerateScenesIdeas.tsx";
import SaveAll from "./SaveAll.tsx";
import { unSnakeCase } from "../../../utils/stringUtil.ts";
import { SpokableButton } from "../SpokableButton.tsx";

export interface GenerateBeatModalProps {
  story: Tables<"blueprint_stories"> | null;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  clickedBranch: Tables<"blueprint_branches"> | null;
  clickedBeatsheet: Tables<"blueprint_beatsheets"> | null;
  branchTransitions: Tables<"blueprint_branch_transitions">[] | null;
}

const GenerateScenesModal: React.FC<GenerateBeatModalProps> = ({
  story,
  beats,
  beatsheets,
  branches,
  characters,
  clickedBranch,
  clickedBeatsheet,
  branchTransitions,
}) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    scenes,
    sceneStatus,
    generateScenes,
    setScenes,
  } = useGenerateSteps();

  const maxStepIndex = 1;

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <GenerateScenesIdeas
            status={sceneStatus}
            generateScenes={generateScenes}
            setScenes={setScenes}
            beats={beats}
            scenes={scenes}
            characters={characters}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
            beatsheets={beatsheets}
            branches={branches}
            branchTransitions={branchTransitions}
          />
        );

      case 1:
        return (
          <SaveAll
            scenes={scenes}
            story={story}
            clickedBranch={clickedBranch}
            clickedBeatsheet={clickedBeatsheet}
            handleClose={handleClose}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-nunito" onClose={handleClose}>
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {clickedBranch && clickedBeatsheet && (
                <div className="pt-6 ml-6">
                  <Badge>{clickedBranch.name}</Badge>
                  <Badge className="ml-2">
                    {unSnakeCase(
                      mergeBeatsAndBeatsheets(beats, [clickedBeatsheet]).pop()?.beat_name || "",
                    )}
                  </Badge>
                </div>
              )}
              <div className="p-6">
                {renderStepContent()}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {stepIndex < maxStepIndex && (
                      <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateScenesModal;
