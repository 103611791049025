import React, { useState } from "react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import NumericStepper from "../NumericStepper.tsx";
import ImageGenerationStyleSelector from "../ImageGenerationStyleSelector.tsx";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";

interface GenerateBaseImageProps {
  initialPrompt: string | null;
  initialPromptModifier: string | null;
  onImageSelected: (imageUrl: string) => void;
}

const GenerateBaseImage: React.FC<GenerateBaseImageProps> = ({
  initialPrompt,
  initialPromptModifier,
  onImageSelected,
}) => {
  const [prompt, setPrompt] = useState(initialPrompt || "");
  const [promptModifier, setPromptModifier] = useState(initialPromptModifier || "");
  const [numberOfImages, setNumberOfImages] = useState(2);
  const [model, setModel] = useState<string | null>(null);
  const { generateImages, imageStatus, images } = useGenerateSteps();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const finalImagePrompt = promptModifier.replace("{prompt}", prompt);

  const handleGenerate = async () => {
    const baseParams = {
      image_generation_prompt: finalImagePrompt,
      number_of_images: numberOfImages,
    };
    const modelParam = model ? { model } : {};
    await generateImages({
      ...baseParams,
      ...modelParam,
    });
    setSelectedImage(null); // Reset selected image when generating new ones
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    onImageSelected(image);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <AutoResizeTextArea className="mb-4" value={prompt} onChange={(e) => setPrompt(e)} />
      <div className="flex items-center space-x-4">
        <div className="flex-grow">
          <ImageGenerationStyleSelector
            promptStyle={promptModifier}
            onPromptStyleSelected={(promptStyle, selectedModel) => {
              setPromptModifier(promptStyle);
              setModel(selectedModel || null);
            }}
          />
        </div>
        <div className="flex self-start">
          <NumericStepper value={numberOfImages} onChange={(value) => setNumberOfImages(value)} />
        </div>
      </div>
      <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
        <SparklesIcon className="mr-2" />
        Generate Images
      </SpokableButton>
      {imageStatus && <div>{imageStatus}</div>}
      {images && (
        <div className="grid grid-cols-2 gap-4 mb-4">
          {images.image_urls.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Generated ${index + 1}`}
              className={`w-full h-auto cursor-pointer ${
                selectedImage === image ? "border-4 border-black" : ""
              }`}
              onClick={() => handleImageClick(image)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GenerateBaseImage;
