import React, { useEffect } from "react";
import { MediaComponent } from "./GameVisualEngine/MediaComponent";
import { useTransitionEffect } from "./GameVisualEngine/UseTransitionEffect";
import { useGetCharacterMediaUrl } from "./GameVisualEngine/UseGetCharacterMediaUrl";
import { useGetBackgroundMediaUrl } from "./GameVisualEngine/UseGetBackgroundMediaUrl";

export const POSITION_BACKGROUND = "background";
export const POSITION_CHARACTER_SPEAKING = "character";
export type Position = typeof POSITION_BACKGROUND | typeof POSITION_CHARACTER_SPEAKING;

interface GameVisualEngineProps {
  storyId: string;
  backgroundImages: string[] | null;
  characterImages: string[] | null;
  currentPosition: Position;
  characterEmotion: string;
  characterEmotionStrength: number;
  isEvent: boolean;
}

const GameVisualEngine: React.FC<GameVisualEngineProps> = ({
  storyId,
  backgroundImages,
  characterImages,
  currentPosition,
  characterEmotion,
  characterEmotionStrength,
  isEvent,
}) => {
  const {
    characterMedia: characterMediaUrl,
    isLoading: isCharacterLoading,
    error: characterError,
    fetchCharacterMedia,
  } = useGetCharacterMediaUrl(storyId, characterImages, characterEmotion, characterEmotionStrength);

  const {
    backgroundMedia: backgroundMediaUrl,
    isLoading: isBackgroundLoading,
    error: backgroundError,
    fetchBackgroundMedia,
  } = useGetBackgroundMediaUrl(storyId, backgroundImages, isEvent);

  const effectClasses = useTransitionEffect(currentPosition);

  useEffect(() => {
    console.log("characterEmotion", characterEmotion);
    fetchCharacterMedia();
    fetchBackgroundMedia();
  }, [
    backgroundImages,
    characterImages,
    currentPosition,
    characterEmotion,
    characterEmotionStrength,
    isEvent,
  ]);

  if (isCharacterLoading || isBackgroundLoading) {
    return <div>Loading...</div>;
  }

  if (characterError || backgroundError) {
    return <div>Error loading media</div>;
  }

  return (
    <div className="game-visual-engine w-full h-full flex items-center justify-center relative overflow-hidden">
      <div className="absolute inset-0">
        <MediaComponent
          src={backgroundMediaUrl}
          alt="background"
          className={`w-full h-full object-cover ${effectClasses.backgroundEffect}`}
        />
      </div>
      <div className="absolute inset-0">
        <MediaComponent
          src={characterMediaUrl}
          alt="character"
          className={`w-full h-full object-cover ${effectClasses.characterEffect}`}
        />
      </div>
    </div>
  );
};

export default GameVisualEngine;
