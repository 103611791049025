import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormField from "../../components/admin/FormField.tsx";
import SpokableListBox from "../../components/admin/SpokableListBox.tsx";
import {
  IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE,
  IMAGE_CONFIG_TYPE_CHARACTER_IMAGE,
  IMAGE_CONFIG_TYPE_TRANSITION,
  IMAGE_EFFECT_PARALLAX_ON_USER_MOVE,
  IMAGE_TRANSITION_EFFECT_FADE,
  imageEffectTypes,
  imageTransitionTypes,
} from "../../constants/constant.ts";
import AnimatedThreeJSImage, { ImageForThree } from "../../components/AnimatedThreeJSImage.tsx";
import { supabase } from "../../vendor/supabaseClient.ts";
import { Tables } from "../../types/database.ts";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/catalyst/listbox.tsx";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";
import { useRecoilState, useRecoilValue } from "recoil";
import { audioContextState } from "../../states/audioDataState.ts";
import { useSetupAudioContext } from "../../hooks/useSetupAudioContext.ts";
import { SideNavLayout } from "../../components/admin/SideNavLayout.tsx";
import { Card, CardContent } from "../../components/admin/Card.tsx";
import {
  lastImageEffectConfigState,
  lastTransitionEffectConfigState,
} from "../../states/effectState.ts";
import { levaStore } from "leva";
import AutoResizeTextArea from "../../components/admin/AutoResizeTextArea.tsx";
import ColorPicker from "../../components/admin/ColorPicker.tsx";
import { updateEffects } from "../../utils/imageEffect.ts";
import { SpokableButton } from "../../components/admin/SpokableButton.tsx";

export default function ImageEffectsConfiguration() {
  const { storyId, configType, chapterId, imageId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [backgroundEffect, setBackgroundEffect] = useState(IMAGE_EFFECT_PARALLAX_ON_USER_MOVE);
  const [characterEffect] = useState(IMAGE_EFFECT_PARALLAX_ON_USER_MOVE);
  const [transitionEffect, setTransitionEffect] = useState(IMAGE_TRANSITION_EFFECT_FADE);
  const [backgroundImage, setBackgroundImage] = useState<ImageForThree | null>(null);
  const [characterImage, setCharacterImage] = useState<ImageForThree | null>(null);
  const [blueprintStory, setBlueprintStory] = useState<Tables<"blueprint_stories">>();
  const [lastImageEffectConfig] = useRecoilState(lastImageEffectConfigState);
  const [lastTransitionEffectConfig] = useRecoilState(lastTransitionEffectConfigState);
  const [characterImages, setCharacterImages] = useState<Tables<"blueprint_character_images">[]>(
    [],
  );
  const [backgroundImages, setBackgroundImages] = useState<Tables<"blueprint_chapter_images">[]>(
    [],
  );
  const { loadBackgroundMusic } = useBackgroundMusicManager();
  const audioContext = useRecoilValue(audioContextState);
  const [backgroundMusicIsSetup, setBackgroundMusicIsSetup] = useState(false);
  const setupAudioContext = useSetupAudioContext();
  const [chapter, setChapter] = useState<Tables<"blueprint_chapters"> | null>(null);
  const [character, setCharacter] = useState<Tables<"blueprint_characters"> | null>(null);

  if (!storyId) return;
  if (!configType) return;
  if (!chapterId) return;

  useEffect(() => {
    setupAudioContext();
    fetchChapterImages();
    fetchChapter();

    fetchCharacterImages();
    fetchStory();
  }, [storyId]);

  useEffect(() => {
    fetchCharacter();
  }, [chapter]);

  useEffect(() => {
    fetchCharacterImages();
  }, [character]);

  useEffect(() => {
    feedThree();
  }, [chapter, characterImages, backgroundImages, configType, backgroundEffect, characterEffect]);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .returns<Tables<"blueprint_stories">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setBlueprintStory(data);
    }
  }

  function feedThree() {
    if (!chapter) return;
    if (!characterImages || characterImages.length === 0) return;
    if (!backgroundImages || backgroundImages.length === 0) return;

    switch (configType) {
      case IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE: {
        const bgImage = backgroundImages.find((image) => image.id === imageId);
        if (!bgImage) return;
        setCharacterImage({
          ...characterImages[0],
          effects: [{ name: characterEffect, config: null }],
          text_overlay_color: chapter.character_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.character_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.character_image_text_overlay_text || "",
        });

        setBackgroundImage({
          ...bgImage,
          effects: [{ name: backgroundEffect, config: null }],
          text_overlay_color: chapter.background_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.background_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.background_image_text_overlay_text || "",
        });
        break;
      }
      case IMAGE_CONFIG_TYPE_CHARACTER_IMAGE: {
        const charImage = characterImages.find((image) => image.id === imageId);
        if (!charImage) return;
        setBackgroundImage({
          ...charImage, // to avoid messing with imageIndex
          effects: [{ name: characterEffect, config: null }],
          text_overlay_color: chapter.character_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.character_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.character_image_text_overlay_text || "",
        });
        setCharacterImage({
          ...charImage,
          effects: [{ name: characterEffect, config: null }],
          text_overlay_color: chapter.character_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.character_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.character_image_text_overlay_text || "",
        });
        break;
      }
      case IMAGE_CONFIG_TYPE_TRANSITION: {
        setCharacterImage({
          ...characterImages[0],
          effects: [{ name: characterEffect, config: null }],
          text_overlay_color: chapter.character_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.character_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.character_image_text_overlay_text || "",
        });
        setBackgroundImage({
          ...backgroundImages[0],
          effects: [{ name: backgroundEffect, config: null }],
          text_overlay_color: chapter.background_image_text_overlay_color || "",
          text_overlay_coordinates: chapter.background_image_text_overlay_coordinates || "",
          text_overlay_text: chapter.background_image_text_overlay_text || "",
        });
        break;
      }
    }
  }

  async function fetchChapter() {
    if (chapterId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_chapters")
      .select("*")
      .eq("id", chapterId)
      .returns<Tables<"blueprint_chapters">[]>()
      .single();

    if (error) {
      setErrorMessage("Error fetching chapter: " + error.message);
    } else {
      setChapter(data);
    }
  }

  const fetchCharacter = async () => {
    if (!chapter) return;
    if (!chapter.blueprint_character_id) return;

    const { data, error } = await supabase
      .from("blueprint_characters")
      .select("*")
      .eq("id", chapter.blueprint_character_id)
      .single();

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacter(data);
    }
  };

  useEffect(() => {
    if (blueprintStory && audioContext && !backgroundMusicIsSetup) {
      loadBackgroundMusic({
        storyId: blueprintStory.id,
        fileName: blueprintStory.sound_intro,
        volume: blueprintStory.sound_intro_volume,
        isLooping: blueprintStory.sound_intro_is_looping,
      });
      setBackgroundMusicIsSetup(true);
    }
  }, [blueprintStory, audioContext]);

  const fetchCharacterImages = async () => {
    if (!chapter) return;
    if (!chapter.blueprint_character_id) return;

    const { data, error } = await supabase
      .from("blueprint_character_images")
      .select("*")
      .eq("blueprint_character_id", chapter.blueprint_character_id);

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setCharacterImages(data);
    }
  };

  const fetchChapterImages = async () => {
    if (!storyId) return;

    const { data, error } = await supabase
      .from("blueprint_chapter_images")
      .select("*")
      .eq("blueprint_story_id", storyId)
      .eq("blueprint_chapter_id", chapterId);

    if (error) {
      console.error("Error fetching character:", error);
    } else {
      setBackgroundImages(data);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    if (!chapter) return;
    if (chapterId == undefined) return;

    console.log("lastImageEffectConfig", lastImageEffectConfig);
    console.log("lastTransitionEffectConfig", lastTransitionEffectConfig);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...chapterWithoutId } = chapter;

    switch (configType) {
      case IMAGE_CONFIG_TYPE_BACKGROUND_IMAGE: {
        if (!imageId) return;
        chapterWithoutId.background_image_effect = updateEffects(
          chapterWithoutId.background_image_effect,
          {
            imageId: imageId,
            name: backgroundEffect,
            config: lastImageEffectConfig,
          },
        );
        chapterWithoutId.background_image_text_overlay_coordinates =
          backgroundImage?.text_overlay_coordinates || "";
        chapterWithoutId.background_image_text_overlay_text =
          backgroundImage?.text_overlay_text || "";
        chapterWithoutId.background_image_text_overlay_color =
          backgroundImage?.text_overlay_color || "";

        break;
      }
      case IMAGE_CONFIG_TYPE_CHARACTER_IMAGE: {
        if (!imageId) return;
        chapterWithoutId.character_speaking_image_effect = updateEffects(
          chapterWithoutId.character_speaking_image_effect,
          {
            imageId: imageId,
            name: backgroundEffect, // to avoid messing with image index with set the character image into the background image
            config: lastImageEffectConfig,
          },
        );
        chapterWithoutId.character_image_text_overlay_coordinates =
          backgroundImage?.text_overlay_coordinates || "";
        chapterWithoutId.character_image_text_overlay_text =
          backgroundImage?.text_overlay_text || "";
        chapterWithoutId.character_image_text_overlay_color =
          backgroundImage?.text_overlay_color || "";
        break;
      }
      case IMAGE_CONFIG_TYPE_TRANSITION: {
        const effects = [];
        if (!Array.isArray(lastTransitionEffectConfig)) {
          throw new Error("Effects should be an array");
        }
        effects.push(lastTransitionEffectConfig[0]);
        console.log("effects", effects);
        chapterWithoutId.character_speaking_transition_effect = {
          name: transitionEffect,
          config: lastTransitionEffectConfig,
        };
        break;
      }
    }

    const { error } = await supabase
      .from("blueprint_chapters")
      .update(chapterWithoutId)
      .eq("id", chapterId);

    if (error) {
      setErrorMessage("Error updating chapter: " + error.message);
    } else {
      navigate(-1);
    }
  };

  return (
    <SideNavLayout showSidePanel={false}>
      <div className="h-full">
        <div className="top-section sticky top-0 backdrop-blur-xl ">
          {errorMessage && (
            <div
              className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
              onClick={() => setErrorMessage(null)}
            >
              {errorMessage}
            </div>
          )}

          <div className="flex justify-between items-end w-full">
            <div className="flex gap-2">
              <SpokableButton color={"light"} onClick={handleCancel}>
                Back
              </SpokableButton>
              <SpokableButton onClick={handleSave}>Save</SpokableButton>
            </div>
          </div>
        </div>
        <div className="flex gap-4 h-full">
          <Card isFullWidth={true} className="w-1/3 ">
            <CardContent className="h-full">
              {/* Controls */}

              <div className="grid grid-cols-1">
                {configType == IMAGE_CONFIG_TYPE_TRANSITION && (
                  <>
                    <FormField label="Background Image:">
                      <Listbox
                        onChange={(selectedImage) => {
                          const typedSelectedImage =
                            selectedImage as Tables<"blueprint_chapter_images">;
                          const imageDetails = {
                            image_url: typedSelectedImage.image_url,
                            has_original: typedSelectedImage.has_original,
                            has_depth_map: typedSelectedImage.has_depth_map,
                            has_version_without_background:
                              typedSelectedImage.has_version_without_background,
                            effects: [{ name: backgroundEffect, config: null }],
                          };
                          setBackgroundImage({ ...backgroundImage, ...imageDetails });
                        }}
                      >
                        {backgroundImages.map((image) => {
                          return (
                            <ListboxOption value={image} key={image.id}>
                              <ListboxLabel>{image.image_url}</ListboxLabel>
                            </ListboxOption>
                          );
                        })}
                      </Listbox>
                    </FormField>
                  </>
                )}
                {configType != IMAGE_CONFIG_TYPE_TRANSITION && (
                  <>
                    <FormField label="Effect on image:">
                      <SpokableListBox
                        options={imageEffectTypes}
                        value={
                          imageEffectTypes.find((type) => type.key === backgroundEffect) ||
                          imageEffectTypes[0]
                        }
                        onChange={(selectedType) => {
                          if (!backgroundImage) return;
                          levaStore.dispose();
                          setBackgroundImage({
                            ...backgroundImage,
                            effects: [{ name: selectedType.key, config: null }],
                          });
                          setBackgroundEffect(selectedType.key);
                        }}
                      />
                    </FormField>
                    <FormField label={"Text"}>
                      <AutoResizeTextArea
                        value={backgroundImage?.text_overlay_text || ""}
                        onChange={(e) => {
                          if (backgroundImage)
                            setBackgroundImage({
                              ...backgroundImage,
                              text_overlay_text: e,
                            });
                        }}
                      />
                    </FormField>
                    <FormField label="Text color:">
                      <ColorPicker
                        initialColor={backgroundImage?.text_overlay_color || ""}
                        onColorChange={(color) => {
                          if (backgroundImage)
                            setBackgroundImage({
                              ...backgroundImage,
                              text_overlay_color: color,
                            });
                        }}
                      ></ColorPicker>
                    </FormField>
                  </>
                )}
                {configType == IMAGE_CONFIG_TYPE_TRANSITION && (
                  <>
                    <FormField label="Character Image:">
                      <Listbox
                        onChange={(selectedImage) => {
                          const typedSelectedImage =
                            selectedImage as Tables<"blueprint_character_images">;
                          const imageDetails: ImageForThree = {
                            image_url: typedSelectedImage.image_url,
                            has_original: typedSelectedImage.has_original,
                            has_depth_map: typedSelectedImage.has_depth_map,
                            has_version_without_background:
                              typedSelectedImage.has_version_without_background,
                            effects: [{ name: characterEffect, config: null }],
                          };
                          setCharacterImage({ ...characterImage, ...imageDetails });
                        }}
                      >
                        {characterImages.map((image) => {
                          return (
                            <ListboxOption value={image} key={image.id}>
                              <ListboxLabel>{image.image_url}</ListboxLabel>
                            </ListboxOption>
                          );
                        })}
                      </Listbox>
                    </FormField>
                  </>
                )}

                {configType == IMAGE_CONFIG_TYPE_TRANSITION && (
                  <>
                    <FormField label="Transition:">
                      <SpokableListBox
                        options={imageTransitionTypes}
                        value={
                          imageTransitionTypes.find((type) => type.key === transitionEffect) ||
                          imageTransitionTypes[0]
                        }
                        onChange={(selectedType) => setTransitionEffect(selectedType.key)}
                      />
                    </FormField>
                    <FormField label="">
                      <SpokableButton
                        onClick={() => (imageIndex === 0 ? setImageIndex(1) : setImageIndex(0))}
                      >
                        {imageIndex === 0 ? "Transition to character" : "Transition to background"}
                      </SpokableButton>
                    </FormField>
                  </>
                )}
              </div>
            </CardContent>
          </Card>
          {/* Images */}
          <Card isFullWidth={true} className="w-2/3 overflow-y-auto relative">
            {/* Images */}
            {characterImage && backgroundImage && (
              <>
                <AnimatedThreeJSImage
                  storyId={storyId}
                  className="absolute inset-0 w-full h-full object-cover z-0"
                  backgroundImages={[backgroundImage]}
                  characterImages={[characterImage]}
                  activeImageIndex={imageIndex}
                  enabledDelayedFade={false}
                  isTextMovable={true}
                  transitionEffects={[{ name: transitionEffect, config: null }]}
                  onTextPositionChange={(newPosition) => {
                    if (backgroundImage)
                      setBackgroundImage({
                        ...backgroundImage,
                        text_overlay_coordinates: JSON.stringify(newPosition),
                      });
                  }}
                />
              </>
            )}
          </Card>
        </div>
      </div>
    </SideNavLayout>
  );
}
