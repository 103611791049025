export function capitalize(str: string | null): string {
  if (!str) return ""; // return the original string if it's empty
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateText(text: string | null, maxLength: number): string {
  if (!text) return "";
  // Check if the text length is more than maxLength
  if (text.length > maxLength) {
    // Return the substring from 0 to maxLength and append "..."
    return text.substring(0, maxLength) + "...";
  }
  // Return the original text if it's shorter than maxLength
  return text;
}

export function sanitizeForURL(input: string): string {
  // Trim the string and replace spaces with hyphens (or underscores, based on your requirement)
  let sanitizedString = input.trim().replace(/\s+/g, "-");

  // Optionally, remove special characters, keeping only alphanumeric characters and dashes
  sanitizedString = sanitizedString.replace(/[^a-zA-Z0-9\-]/g, "");

  // Finally, encode the URI component to make it safe for use in URLs
  return encodeURIComponent(sanitizedString);
}

export function unSnakeCase(snakeCasedText: string): string {
  return capitalize(snakeCasedText.replace(/_/g, " "));
}
