import React, { useState } from "react";
import { Field } from "../catalyst/fieldset.tsx";
import { Listbox, ListboxLabel, ListboxOption } from "../catalyst/listbox.tsx";
import { Json } from "../../types/database.ts";

export interface Tag {
  key: string;
  value: string;
}

interface SelectProps {
  tags: Tag[];
  value: Json;
  onChange: (selectedTags: Json) => void;
}

const SpokableTagSelector: React.FC<SelectProps> = ({ tags, value, onChange }) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    Array.isArray(value) ? (value as unknown as Tag[]) : [],
  );

  tags = tags.sort((a, b) => a.value.localeCompare(b.value));

  const handleAddTag = (tag: Tag) => {
    if (!selectedTags.some((t) => t.key === tag.key)) {
      const newSelectedTags = [...selectedTags, tag];
      setSelectedTags(newSelectedTags);
      onChange(newSelectedTags.map((tag) => ({ key: tag.key, value: tag.value })));
    }
  };

  const handleRemoveTag = (key: string) => {
    const newSelectedTags = selectedTags.filter((tag) => tag.key !== key);
    setSelectedTags(newSelectedTags);
    onChange(newSelectedTags.map((tag) => ({ key: tag.key, value: tag.value })));
  };

  return (
    <div className="space-y-2">
      <Field>
        <Listbox
          name="option"
          onChange={(key) => {
            const selectedTag = tags.find((o) => o.key === key);
            if (selectedTag) {
              handleAddTag(selectedTag);
            }
          }}
        >
          {tags.map((option) => (
            <ListboxOption key={option.key} value={option.key} className="font-nunito">
              <ListboxLabel>{option.value}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      </Field>
      <div className="flex flex-wrap">
        {selectedTags.map((tag) => (
          <span
            key={tag.key}
            className="m-1 px-2 py-1 border text-sm border-gray-300 rounded-md inline-flex items-center"
          >
            {tag.value}
            <button
              onClick={() => handleRemoveTag(tag.key)}
              className="ml-2 text-red-500 hover:text-red-700"
            >
              x
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default SpokableTagSelector;
