import React from "react";
import { useRecoilState } from "recoil";
import { clickedCatalogueImageState, modalState } from "./State.ts";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, DialogPanel } from "@headlessui/react";
import { ESRBKey, getRatingSvgPath } from "../../constants/rating.ts";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  MicrophoneIcon,
  PlayIcon,
  PlusIcon,
  ShareIcon,
} from "@heroicons/react/16/solid";
import { Button } from "../catalyst/button.tsx";
import { getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import { catalogueSelectedStoryState } from "../../states/catalogueState.ts";
import { computeRecommendationPercentage, formatDuration } from "../../utils/math.ts";
import { getYear, parseISO } from "date-fns";
import { Tag } from "../admin/SpokableTagSelector.tsx";
import { useNavigate } from "react-router-dom";

interface ModalProps {}

const StoryModal: React.FC<ModalProps> = () => {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [initialRect] = useRecoilState(clickedCatalogueImageState);
  const [story] = useRecoilState(catalogueSelectedStoryState);
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      {isOpen && initialRect && (
        <Dialog static open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80"
          />
          <div className="fixed inset-0 flex items-center justify-center">
            <DialogPanel
              as={motion.div}
              initial={{
                opacity: 0,
                left: initialRect.left,
                top: initialRect.top,
                width: initialRect.width,
                height: initialRect.height,
                position: "fixed",
              }}
              animate={{
                opacity: 1,
                scale: 1,
                left: "50%",
                top: "50%",
                width: "60%",
                height: "90%",
                transform: "translate(-50%, -50%)",
              }}
              exit={{
                opacity: 0,
                left: initialRect.left,
                top: initialRect.top,
                width: initialRect.width,
                height: initialRect.height,
                position: "fixed",
                transform: "translate(0, 0)",
              }}
              className="rounded-md border border-brand-yellow bg-black shadow-xl overflow-y-auto"
            >
              <div className="relative group/modal ">
                <div className="m-0 p-0 border-black border-b-2 overflow-hidden">
                  <img
                    src={getSupabaseImageUrl(story?.id, story?.background_image) || ""}
                    className="cursor-pointer rounded-t-md object-cover w-full h-[24vw]
                     group-hover/modal:scale-125 duration-1000"
                    alt="thumbnail"
                  />
                  {story?.story_logo_image && (
                    <div className="absolute inset-0 flex justify-center items-center z-10">
                      <img
                        src={
                          story?.story_logo_image
                            ? (getSupabaseImageUrl(story?.id, story?.story_logo_image) as string)
                            : ""
                        }
                        alt="Centered Image"
                        className="w-auto h-auto max-w-[20%] max-h-[20%] object-contain"
                      />
                    </div>
                  )}
                  <p
                    className="absolute bottom-20 left-16 text-1xl md:text-5xl
                   w-[50%] lg:text-6xl font-bold drop-shadow-xl uppercase"
                    style={{ color: story?.text_rgba_color || "white" }}
                  >
                    {story?.name}
                  </p>
                  <div
                    className="absolute bottom-0 left-0 right-0 h-32 z-50
                   bg-gradient-to-t from-black to-transparent space-x-4 text-white p-16"
                  >
                    <Button color={"light"} onClick={() => navigate(`/launch/${story?.id}`)}>
                      <PlayIcon className="h-6 w-6 text-white" />
                      Launch
                    </Button>
                    <Button className="p-2 rounded-full bg-gray-800">
                      <PlusIcon className="h-6 w-6 text-white" />
                    </Button>
                    <Button className="p-2 rounded-full bg-gray-800">
                      <HandThumbUpIcon className="h-6 w-6 text-white" />
                    </Button>
                    <Button className="p-2 rounded-full bg-gray-800">
                      <HandThumbDownIcon className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                </div>
              </div>
              <motion.div
                className="p-16 space-y-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <div className="bg-gray-900 text-white p-8">
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex-2">
                      <div className="flex items-center space-x-2">
                        <span className="text-brand-yellow text-lg font-semibold">
                          {`${computeRecommendationPercentage(story?.positive_count, story?.negative_count)}% Recommended`}
                        </span>
                      </div>
                      <div className="flex items-center space-x-6">
                        {story?.created_at && (
                          <span className="text-gray-400">
                            {getYear(parseISO(story?.created_at))}
                          </span>
                        )}
                        <span className="text-gray-400">
                          {`${formatDuration(story?.estimated_minute_duration || 0)} (Approx)`}{" "}
                        </span>

                        <span className="bg-gray-700 text-xs px-2 py-1 rounded">HD</span>
                      </div>
                      <p className="mt-4" style={{ whiteSpace: "pre-line" }}>
                        {story?.catalogue_description}
                      </p>
                    </div>
                    <div className="flex-1 mt-8 md:mt-0 md:ml-8">
                      <div className="flex items-center space-x-2 mt-2">
                        {story?.esbr_rating && (
                          <img src={getRatingSvgPath(story?.esbr_rating as ESRBKey)} alt="rating" />
                        )}
                        <span className="text-gray-400">
                          {story?.esbr_tags &&
                            Array.isArray(story?.esbr_tags) &&
                            story?.esbr_tags.map((category, index) => (
                              <React.Fragment key={index}>
                                <span>{(category as unknown as Tag).value}</span>
                                {story?.genre_tags &&
                                  Array.isArray(story?.esbr_tags) &&
                                  index < story.esbr_tags.length - 1 && <span>, </span>}
                              </React.Fragment>
                            ))}
                        </span>
                      </div>
                      <div className="mt-2">
                        <span className="text-gray-400">Genres:</span>{" "}
                        {story?.genre_tags &&
                          Array.isArray(story?.genre_tags) &&
                          story?.genre_tags.map((category, index) => (
                            <React.Fragment key={index}>
                              <span>{(category as unknown as Tag).value}</span>
                              {story?.genre_tags &&
                                Array.isArray(story?.genre_tags) &&
                                index < story.genre_tags.length - 1 && <span>, </span>}
                            </React.Fragment>
                          ))}
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="border border-gray-400 text-xs px-2 py-2 rounded inline-flex items-center space-x-2 mt-4">
                          <div className="flex items-center justify-center">
                            <MicrophoneIcon className="w-4 h-4 text-gray-400" />
                          </div>
                          <span className="text-center flex-1 min-w-0 text-gray-400">
                            {story?.interactivity_level}
                          </span>
                        </div>
                        <div className="border border-gray-400 text-xs px-2 py-2 rounded inline-flex items-center space-x-2 mt-4">
                          <div className="flex items-center justify-center">
                            <ShareIcon className="w-4 h-4 text-gray-400" />
                          </div>
                          <span className="text-center flex-1 min-w-0 text-gray-400">
                            {story?.branching_level}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default StoryModal;
